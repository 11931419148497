import './UnderConstraction.scss'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Image1 from '../../../assets/underConstraction.png'
import { FormattedMessage } from 'react-intl'

export default function UnderConstraction() {
  const navigate = useNavigate()
  return (
    <div className="underConstraction">
      <img src={Image1} alt={Image1} />
      <div className="underConstraction-text">
        <h1>
          <FormattedMessage
            defaultMessage="Sorry, we're doing some work on the site"
            id="UnderCTitle"
          />
        </h1>
        <p>
          <FormattedMessage
            defaultMessage="Thank you for being patient. We are doing some work on the site and will be back shortly."
            id="UnderCSubtitle"
          />
        </p>
        <button className="underConstraction-button">
          <Link to="/" style={{ color: '#000' }}>
            <FormattedMessage defaultMessage="Go Home" id="GoHome" />
          </Link>
        </button>
        <button
          onClick={() => navigate(-1)}
          className="underConstraction-button"
        >
          <FormattedMessage defaultMessage="Go Back" id="GoBack" />
        </button>
      </div>
    </div>
  )
}
