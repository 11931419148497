import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from 'react-router-dom'
import { dataLocalStorage } from '../localStorage'
import Navbar from '../components/shared/navbar/Navbar'
import Home from './Home'
import Footer from '../components/shared/footer/Footer'
import OurWork from './OurWork'
import ContactUs from './ContactUs'
import UnderConstraction from '../components/shared/underConstruction/UnderConstraction'
import ScrollToTopIcon from '../components/shared/scrollToTopIcon/ScrollToTopIcon'
import ScrollToTop from '../ScrollToTop'
import Cookies from '../components/shared/cookies/Cookies'
import Impressum from './Impressum'

export default function Routes(props) {
  const [cookies, setCookies] = useState(dataLocalStorage.getCookies())

  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/our-work" element={<OurWork />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/impressum-dataprotection" element={<Impressum />} />
        <Route path="*" exact element={<UnderConstraction />} />
      </Switch>
      <Footer language={props.language} setLanguage={props.setLanguage} />
      <Cookies cookies={cookies} setCookies={setCookies} />
      <ScrollToTopIcon />
      <ScrollToTop />
    </Router>
  )
}
