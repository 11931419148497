import React from 'react'

function SingleLanguage({ styles, flag, language, changeLanguage }) {
  return (
    <div
      className={`single-language flex align-center ${styles}`}
      onClick={changeLanguage}
    >
      <img src={flag} alt="flag" />
      <span>{language}</span>
    </div>
  )
}

export default SingleLanguage
