import '../contactCards/ContactCards.scss'

export default function ContactMap() {
  return (
    <div className="contactMap" data-aos="fade-up" data-aos-delay="200">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2710.5836796681483!2d8.752993815616614!3d47.2051605791602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ab168fde9fb13%3A0x1a99edc4569e0e0d!2sLeutschenstrasse%2029%2C%208807%20Freienbach%2C%20Switzerland!5e0!3m2!1sen!2s!4v1636777710778!5m2!1sen!2s"
        width="600"
        height="450"
        style={{ border: '9' }}
        allowFullScreen=""
        loading="lazy"
        title="map-location"
      ></iframe>
    </div>
  )
}
