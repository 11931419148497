import React, { useState, useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { IntlProvider } from 'react-intl'
import { dataLocalStorage } from './localStorage'
import { translate } from './translations/index'
import Routes from './routes/Routes'

export default function App() {
  const [language, setLanguage] = useState(
    dataLocalStorage.getLocale() || 'de-DE'
  )

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  }, [])

  window.onbeforeunload = function () {
    window.scrollTo(0, 0)
  }
  return (
    <div className="App">
      <IntlProvider
        locale={language}
        formats={{ number: 'de' }}
        messages={translate[language]}
      >
        <Routes language={language} setLanguage={setLanguage} />
      </IntlProvider>
    </div>
  )
}
