import './ContactCards.scss'
import { BsCheckCircleFill } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'

const data = [
  {
    title: <FormattedMessage defaultMessage="Call Us" id="CallUs" />,
    text: '+41 (0) 764130514',
    link: 'tel:+41764130514',
    dataAos: 'fade-right',
  },
  {
    title: <FormattedMessage defaultMessage="Email" id="Email" />,
    text: 'info@sigreen.ch',
    link: 'mailto:info@sigreen.ch',
    dataAos: 'fade-up',
  },
  {
    title: <FormattedMessage defaultMessage="Location" id="Location" />,
    text: 'Leutschenstrasse 29, 8807 Freienbach',
    link: 'https://goo.gl/maps/FuqN9r32R8f4puse9',
    dataAos: 'fade-left',
  },
]

function ContactCard({ title, text, link, dataAos }) {
  return (
    <a
      href={link}
      target="blank"
      className="contactCard flex column align-center justify-center"
      data-aos={dataAos}
      data-aos-delay="300"
    >
      <span className="contactCard-icon">
        <BsCheckCircleFill />
      </span>
      <h1 className="contactCard-title">{title}</h1>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="contactCard-link"
      >
        {text}
      </a>
    </a>
  )
}
export default function ContactCards() {
  return (
    <div className="contactCards-top">
      <h1 className="title" data-aos="fade-up" style={{ marginBottom: '30px' }}>
        <FormattedMessage defaultMessage="Contact Us" id="ContactUs" />
      </h1>
      {/* <h2 className="subtitle" data-aos="fade-up" data-aos-delay="200">
        <FormattedMessage
          defaultMessage="We are a company with traditon over 30 years on working with garden of thousands family, but for better we show you our latest work here."
          id="Works.Subtitle"
        />
      </h2> */}
      <div className="contactCards flex flex-wrap space-evenly">
        {data.map((i, index) => {
          return (
            <ContactCard
              title={i.title}
              link={i.link}
              text={i.text}
              dataAos={i.dataAos}
              key={index}
            />
          )
        })}
      </div>
    </div>
  )
}
