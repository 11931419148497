import './Footer.scss'
import { AiOutlineInstagram as InstagramIcon } from 'react-icons/ai'
import SelectLanguage from '../selectLanguage/SelectLanguage'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const data = [
  {
    title: <FormattedMessage defaultMessage="Social Media" id="SocialMedia" />,
    datas: [
      {
        text: 'Instagram',
        icon: <InstagramIcon />,
        link: 'https://www.instagram.com/si_green_gmbh/',
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Contact Us" id="ContactUs" />,
    datas: [
      {
        text: 'SI GREEN GmbH',
        link: 'http://sigreen.ch',
      },
      {
        text: 'Leutschenstrasse 29, 8807 Freienbach',
        link: 'https://goo.gl/maps/FuqN9r32R8f4puse9',
      },
      {
        text: '+41 (0) 764130514',
        link: 'tel:+41764130514',
      },
      {
        text: 'info@sigreen.ch',
        link: 'mailto:info@sigreen.ch',
      },
    ],
  },
]
function FooterSocialsRow({ title, datas }) {
  return (
    <div className="footer-top-row" data-aos="zoom-in">
      <p className="footer-top-row-title">{title}</p>
      <div className="footer-top-row-content flex column">
        {datas.map((i, index) => {
          return (
            <a
              href={i.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              className="flex align-center"
            >
              {i.icon}
              {i.text}
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default function Footer(props) {
  return (
    <div className="footer">
      <div className="footer-top flex space-evenly flex-wrap">
        {data.map((i, index) => {
          return (
            <FooterSocialsRow title={i.title} datas={i.datas} key={index} />
          )
        })}
      </div>
      <div className="footer-bottom">
        <Link to="/impressum-dataprotection" className="impressum-link">
          <FormattedMessage
            defaultMessage="Impressum & Data Protection"
            id="Impressum"
          />
        </Link>
        <hr />
        <p>
          SI GREEN GmbH ©
          <FormattedMessage
            defaultMessage=" All Right Reserved by it-wellen.de"
            id="AllRightReserved"
          />
        </p>
        <SelectLanguage
          setLanguage={props.setLanguage}
          language={props.language}
        />
      </div>
    </div>
  )
}
