import './Cookies.scss'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { dataLocalStorage } from '../../../localStorage'

export default function Cookies({ cookies, setCookies }) {
  const [hideCookies, setHideCookies] = useState(false)

  useEffect(() => {
    let cookies = dataLocalStorage.getCookies()
    if (cookies !== null) {
      setHideCookies(true)
    }
  }, [])

  const setCookiesHandler = () => {
    dataLocalStorage.setCookies()
    setHideCookies(true)
  }

  return !hideCookies ? (
    <div className={`cookies ${hideCookies}`}>
      <h3>
        <FormattedMessage
          defaultMessage="This website uses cookies. By continuing to use the website, you consent to the use of cookies."
          id="Cookies"
        />
      </h3>
      <div className="cookies-buttons">
        <Link to="/impressum-dataprotection">
          <button className="cookies-button">
            <FormattedMessage defaultMessage="Learn More" id="LearnMore" />
          </button>
        </Link>

        <button className="cookies-button" onClick={setCookiesHandler}>
          <FormattedMessage
            defaultMessage="Accept Cookies"
            id="AcceptCookies"
          />
        </button>
      </div>
    </div>
  ) : (
    ''
  )
}
