import './Works.scss'
import { FormattedMessage } from 'react-intl'
import Button from '../shared/button/Button'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import Image1 from '../../assets/image2.jpg'
import Image2 from '../../assets/image3.jpg'
import Image3 from '../../assets/image8.jpg'
import Image4 from '../../assets/image9.jpg'
import Image5 from '../../assets/image12.jpg'
import Image6 from '../../assets/image11.jpg'
import Image7 from '../../assets/image13.jpg'
import Image8 from '../../assets/image14.jpg'

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 2.5 },
}

const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8]
const items = []

for (let i = 0; i < images.length; i++) {
  items.push(
    <div
      style={{ backgroundImage: `url(${images[i]})` }}
      className="item"
      data-value={i + 1}
    />
  )
}

export default function Works() {
  return (
    <div className="works">
      <h2 className="subtitle" data-aos="fade-up">
        <FormattedMessage
          defaultMessage="These three words describe working with us."
          id="Text4"
        />
        <br />
        <FormattedMessage
          defaultMessage="We at SI GREEN GmbH are a young company with headquarters in the municipality of Freienbach."
          id="Text5"
        />
        <br />
        <FormattedMessage
          defaultMessage="Managing Director Spidi Bajrami,"
          id="Text6"
        />
        <br />
        <FormattedMessage
          defaultMessage="has been involved in gardening for 20 years and has an open ear for your personal ideas when designing your dream garden."
          id="Text7"
        />
        <br />
        <FormattedMessage
          defaultMessage="We would be happy to advise you, contact us!"
          id="Text8"
        />
      </h2>
      <h1
        className="title"
        data-aos="fade-up"
        data-aos-delay="200"
        style={{ marginBottom: '20px' }}
      >
        <FormattedMessage defaultMessage="Latest Works" id="Works.Title" />
      </h1>
      <div data-aos="fade-right" data-aos-delay="300">
        <AliceCarousel mouseTracking items={items} responsive={responsive} />
      </div>
      <Button
        text={
          <FormattedMessage defaultMessage="More Examples" id="MoreExamples" />
        }
        link="/our-work"
        className="green"
        dataAos="fade-up"
        dataAosDelay="150"
      />
    </div>
  )
}
