import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import ContactCards from '../components/contactCards/ContactCards'
import ContactMap from '../components/contactMap/ContactMap'

export default function ContactUs() {
  return (
    <>
      <FormattedMessage
        defaultMessage="SI GREEN GmbH | Contact Us"
        id="Title.ContactUs"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <ContactCards />
      <ContactMap />
    </>
  )
}
