const LOCALE = 'locale'
const LANGUAGE = 'language'
const COOKIES = 'Cookies saved!'
var now = new Date()
var hours = Math.ceil(now.getTime() / 3600000)
var expireHours = hours + 30 * 24

export const dataLocalStorage = {
  setLocale(locale) {
    localStorage.setItem(LOCALE, locale)
  },

  getLocale() {
    return localStorage.getItem(LOCALE)
  },

  setLanguage(lang) {
    localStorage.setItem(LANGUAGE, lang)
  },
  getLanguage() {
    return localStorage.getItem(LANGUAGE)
  },

  setCookies() {
    localStorage.setItem(COOKIES, hours)
  },
  getCookies() {
    if (expireHours - hours < 720) {
      localStorage.removeItem(COOKIES, hours)
      return localStorage.getItem(COOKIES, hours)
    } else {
      return localStorage.getItem(COOKIES, hours)
    }
  },
}
