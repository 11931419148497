import Banner from '../components/banner/Banner'
import Works from '../components/works/Works'
import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'

export default function Home() {
  return (
    <>
      <FormattedMessage defaultMessage="SI GREEN GmbH" id="Title.Home">
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <Banner />
      <Works />
    </>
  )
}
