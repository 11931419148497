import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import ImpressumPage from '../components/impressum/ImpressumPage'

export default function Impressum() {
  return (
    <>
      <FormattedMessage
        defaultMessage="SI GREEN GmbH | Impressum & Data Protection"
        id="Title.Impressum"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <ImpressumPage />
    </>
  )
}
