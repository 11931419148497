import React, { useState, useCallback } from 'react'
import './Prices.scss'
import ImageViewer from 'react-simple-image-viewer'
import { FormattedMessage } from 'react-intl'
import Image1 from '../../assets/work.jpg'
import { FiDownload } from 'react-icons/fi'

export default function Prices() {
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const images = [Image1]

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <div className="prices flex align-center space-evenly">
      <div className="prices-text">
        <h1 className="title" data-aos="fade-right">
          <FormattedMessage defaultMessage="Our Work" id="OurWork" />
        </h1>
      </div>
      <div className="prices-photo flex column align-center text-center">
        {images.map((src, index) => (
          <img
            src={src}
            onClick={() => openImageViewer(index)}
            width="450"
            key={index}
            alt={src}
          />
        ))}
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}
            closeOnClickOutside={true}
          />
        )}
        <p>
          <FormattedMessage
            defaultMessage="Click on image to expand, or "
            id="ExpandImageOr"
          />
          <a href={Image1} download>
            <FiDownload />
            <FormattedMessage
              defaultMessage="Click Here Download this image!"
              id="DownloadImage"
            />
          </a>
        </p>
      </div>
    </div>
  )
}
