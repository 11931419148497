import './Button.scss'
import { Link } from 'react-router-dom'

export default function Button({
  className,
  link,
  text,
  dataAos,
  dataAosDelay,
}) {
  return (
    <Link
      to={link}
      className={`button flex flex-align align-center justify-center text-center ${className}`}
      data-aos={dataAos}
      data-aos-delay={dataAosDelay}
    >
      {text}
    </Link>
  )
}
