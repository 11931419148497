import React, { useState, useCallback } from 'react'
import './SomeOfWorks.scss'
import ImageViewer from 'react-simple-image-viewer'
import { FormattedMessage } from 'react-intl'
import Image1 from '../../assets/image2.jpg'
import Image2 from '../../assets/image3.jpg'
import Image3 from '../../assets/image8.jpg'
import Image4 from '../../assets/image9.jpg'
import Image5 from '../../assets/image12.jpg'
import Image6 from '../../assets/image11.jpg'
import Image7 from '../../assets/image13.jpg'
import Image8 from '../../assets/image14.jpg'
import Image9 from '../../assets/image0.jpg'
import Image10 from '../../assets/image1.jpg'
import Image11 from '../../assets/image5.jpg'
import Image13 from '../../assets/image7.jpg'
import Image12 from '../../assets/image6.jpg'
import Image14 from '../../assets/image16.jpg'
import Image15 from '../../assets/image15.jpg'

export default function SomeOfWorks() {
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const data = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
    Image13,
    Image14,
    Image15,
  ]

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  return (
    <div className="someOfWorks">
      <h1 className="title" data-aos="fade-up" style={{ marginBottom: '50px' }}>
        <FormattedMessage
          defaultMessage="Some of our works"
          id="SomeOfOurWorks"
        />
      </h1>
      <p data-aos="fade-up">
        <FormattedMessage
          defaultMessage="Click on image, to expand it!"
          id="ExpandImage"
        />
      </p>
      <div className="someOfWorks-imgs flex flex-wrap align-center space-evenly">
        {data.map((src, index) => (
          <img
            src={src}
            onClick={() => openImageViewer(index)}
            key={index}
            alt={src}
          />
        ))}
        {isViewerOpen && (
          <ImageViewer
            src={data}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </div>
  )
}
