import './Banner.scss'
import { FormattedMessage } from 'react-intl'
import Button from '../shared/button/Button'

export default function Banner() {
  return (
    <div className="banner flex align-center">
      <div className="b-text flex column">
        <h1 data-aos="fade-right">
          <div>
            <span />
            <FormattedMessage defaultMessage="Competently." id="Text1" />
          </div>
          <div>
            <span />
            <FormattedMessage defaultMessage="Flexible." id="Text2" />
          </div>
          <div>
            <span />
            <FormattedMessage defaultMessage="Straightforward." id="Text3" />
          </div>
        </h1>
        <div className="b-buttons flex align-center space-between">
          <Button
            text={<FormattedMessage defaultMessage="About Us" id="AboutUs" />}
            link="/our-work"
            className="bordered"
            dataAos="fade-up"
            dataAosDelay="150"
          />
          <Button
            text={
              <FormattedMessage defaultMessage="Contact Us" id="ContactUs" />
            }
            link="/contact-us"
            className="filled"
            dataAos="fade-up"
            dataAosDelay="150"
          />
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 260"
        className="b-svg"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,224L48,208C96,192,192,160,288,160C384,160,480,192,576,186.7C672,181,768,139,864,138.7C960,139,1056,181,1152,208C1248,235,1344,245,1392,250.7L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  )
}
