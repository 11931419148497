import React, { Component } from 'react'
import './ScrollToTopIcon.scss'
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-up.svg'

export default class ScrollToTopIcon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: window.innerHeight,
      bottomReached: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }
  handleScroll() {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight
    const body = document.body
    const html = document.documentElement
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const windowBottom = windowHeight + window.pageYOffset
    if (windowBottom + 250 >= docHeight) {
      this.setState({
        bottomReached: true,
      })
    } else {
      this.setState({
        bottomReached: false,
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  render() {
    return (
      <div
        className={`scrollTopIcon ${this.state.bottomReached}`}
        onClick={this.scrollTop}
      >
        <ArrowUp />
      </div>
    )
  }
}
