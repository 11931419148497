import { FormattedMessage } from 'react-intl'
import './ImpressumPage.scss'

export default function ImpressumPage() {
  return (
    <div className="impressumPage">
      <h1 className="title" data-aos="fade-up">
        <FormattedMessage
          defaultMessage="Impressum & Data Protection"
          id="Impressum"
        />
      </h1>
      <p className="impressumSubtitle">
        <FormattedMessage
          defaultMessage="Contact Address"
          id="ContactAddress"
        />
      </p>
      <p className="impressumText">SI GREEN GmbH</p>
      <p className="impressumText">Leutschenstrasse 29</p>
      <p className="impressumText">8807 Freienbach</p>
      <p className="impressumText">Schweiz</p>

      <p className="impressumSubtitle">
        <FormattedMessage
          defaultMessage="Authorized Representatives"
          id="AuthorizedRep"
        />
      </p>
      <p className="impressumText">Bajrami Ilir</p>

      <p className="impressumSubtitle">
        <FormattedMessage
          defaultMessage="Entry in the commercial register"
          id="EntryCommercialReg"
        />
      </p>
      <p className="impressumText">
        <FormattedMessage
          defaultMessage="Registered company name:"
          id="RegComName"
        />
      </p>

      <p className="impressumText">SI GREEN GmbH</p>
      <p className="impressumText">
        <FormattedMessage defaultMessage="Number" id="Number" />
      </p>
      <p className="impressumText">
        <FormattedMessage
          defaultMessage="Commercial Register Office: "
          id="CommRegOff"
        />
        Zürich
      </p>

      <p className="impressumSubtitle">
        <FormattedMessage
          defaultMessage="Disclaimer of liability"
          id="DisclamerOfLiability"
        />
      </p>
      <p className="impressumText second">
        <FormattedMessage
          defaultMessage="The author assumes no liability for the correctness, accuracy, timeliness, reliability and completeness of the information."
          id="TheAuthorAssumes"
        />
      </p>
      <p className="impressumText second">
        <FormattedMessage
          defaultMessage="Liability claims against the author for material or immaterial damage resulting from access to, use or non-use of the published information, from misuse of the connection or from technical malfunctions are excluded."
          id="LiabilityClaims"
        />
      </p>
      <p className="impressumText second">
        <FormattedMessage
          defaultMessage="All offers are non-binding. The author expressly reserves the right to change, add to, or delete parts of the pages or the entire offer or to cease publication temporarily or permanently without prior notice."
          id="AllOffers"
        />
      </p>

      <p className="impressumSubtitle">
        <FormattedMessage
          defaultMessage="Liability for links"
          id="LiabilityLinks"
        />
      </p>
      <p className="impressumText second">
        <FormattedMessage
          defaultMessage="References and links to third party websites are outside our area of ​​responsibility. It rejected any responsibility for such websites. Access to and use of such websites is at the user's own risk."
          id="ReferencesAndLinks"
        />
      </p>

      <p className="impressumSubtitle">
        <FormattedMessage defaultMessage="Copyrights" id="Copyrights" />
      </p>
      <p className="impressumText second">
        <FormattedMessage
          defaultMessage="The copyright and all other rights to content, images, photos or other files on the website belong exclusively to SI Green GmbH or the specifically named rights holders. The written consent of the copyright holder must be obtained in advance for the reproduction of any elements."
          id="TheCopyrightAndAllOther"
        />
      </p>

      <p className="impressumSubtitle">
        <FormattedMessage
          defaultMessage="Data protection"
          id="DataProtection"
        />
      </p>
      <p className="impressumText second">
        <FormattedMessage
          defaultMessage="Based on Article 13 of the Swiss Federal Constitution and the federal data protection provisions (Data Protection Act, DSG), everyone has the right to the protection of their privacy and protection against misuse of their personal data. We adhere to these regulations. Personal data is treated as strictly confidential and is neither sold nor passed on to third parties."
          id="BasedOnArticle13"
        />
      </p>
      <p className="impressumText second">
        <FormattedMessage
          defaultMessage="In close cooperation with our hosting providers, we strive to protect the databases as well as possible from unauthorized access, loss, misuse or falsification. Our website is hosted by the provider Hostpoint.ch, whose servers are located in Switzerland."
          id="InCloseCooperation"
        />
      </p>
      <p className="impressumText second">
        <FormattedMessage
          defaultMessage="When you access our website, the following data is stored in log files: IP address, date, time, browser request and general information about the operating system and / or the operating system. Browser. This usage data forms the basis for statistical, anonymous evaluations so that trends can be identified, which we can use to improve our offers accordingly."
          id="WhenYouAccessOur"
        />
      </p>
    </div>
  )
}
