import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import Prices from '../components/prices/Prices'
import SomeOfWorks from '../components/someOfWorks/SomeOfWorks'

export default function OurWork() {
  return (
    <>
      <FormattedMessage
        defaultMessage="SI GREEN GmbH | Our Work"
        id="Title.OurWork"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <Prices />
      <SomeOfWorks />
    </>
  )
}
