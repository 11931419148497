import './Navbar.scss'
import { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { BsFillTelephoneFill as PhoneIcon } from 'react-icons/bs'
import { GrMail as MailIcon } from 'react-icons/gr'
import { AiOutlineInstagram as InstagramIcon } from 'react-icons/ai'
import { Spin as Hamburger } from 'hamburger-react'
// import Logo from '../../../assets/logo.png'
import Dropdown from './dropdown/Dropdown'

export default function Navbar(props) {
  const [openedDropdown, setOpenedDropdown] = useState(false)

  const location = useLocation()
  useEffect(() => {}, [location.pathname])

  let useClickOutside = (handler) => {
    let reference = useRef()
    useEffect(() => {
      let maybeHandler = (event) => {
        if (!reference.current.contains(event.target)) {
          handler()
        }
      }
      document.addEventListener('mousedown', maybeHandler)
      return () => {
        document.removeEventListener('mousedown', maybeHandler)
      }
    })
    return reference
  }

  let reference = useClickOutside(() => {
    if (openedDropdown) {
      setOpenedDropdown(false)
    }
  })

  const SocailIconLink = ({ link, icon }) => {
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="social-icon-link flex align-center justify-center"
      >
        {icon}
      </a>
    )
  }

  const socialIconsAndLinks = [
    {
      link: 'https://www.instagram.com/si_green_gmbh/',
      icon: <InstagramIcon />,
    },
  ]

  return (
    <div className={`navbar flex space-between align-center`} ref={reference}>
      <Link
        to="/"
        onClick={() => {
          window.scrollTo(0, 0)
        }}
        className="n-logo flex align-center"
        data-aos="fade-right"
      />
      <div
        className="n-contacts flex align-center space-evenly"
        data-aos="fade-down"
      >
        <a
          href="tel:+41764130514"
          className="n-contacts-link flex align-center"
        >
          <PhoneIcon />
          <span
            className="n-contacts-link-text"
            style={{ letterSpacing: '3px' }}
          >
            +41 (0) 764130514
          </span>
        </a>
        |
        <a
          href="mailto:info@sigreen.ch"
          className="n-contacts-link flex align-center"
        >
          <MailIcon />
          <span
            className="n-contacts-link-text"
            style={{ letterSpacing: '2px' }}
          >
            info@sigreen.ch
          </span>
        </a>
      </div>
      <div
        className="n-right flex align-center justify-end"
        data-aos="fade-left"
      >
        <div className="n-right-socials flex">
          {socialIconsAndLinks.map((i, index) => {
            return <SocailIconLink link={i.link} icon={i.icon} key={index} />
          })}
        </div>
        <div className="n-right-hamburger">
          <Hamburger
            color="#000"
            toggled={openedDropdown}
            toggle={setOpenedDropdown}
          />
        </div>
      </div>
      <Dropdown
        openedDropdown={openedDropdown}
        onClick={() => setOpenedDropdown(false)}
      />
    </div>
  )
}
