import { useState } from 'react'
import './SelectLanguage.scss'
import germanyFlag from '../../../assets/icons/germany.svg'
import ukFlag from '../../../assets/icons/uk.svg'
import { dataLocalStorage } from '../../../localStorage'
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg'
import SingleLanguage from './SingleLanguage'

function SelectLanguage(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const languages = [
    {
      value: 'de-DE',
      lang: 'German',
      flag: germanyFlag,
    },
    {
      value: 'en-UK',
      lang: 'English',
      flag: ukFlag,
    },
  ]

  const changeLanguage = (language, locale) => {
    props.setLanguage(locale)
    dataLocalStorage.setLanguage(language)
    dataLocalStorage.setLocale(locale)
  }

  let flag = null
  switch (props.language) {
    case 'de-DE':
      flag = germanyFlag
      break
    case 'en-UK':
      flag = ukFlag
      break
    default:
      flag = germanyFlag
  }

  return (
    <div className={`select-language flex align-center ${dropdownOpen}`}>
      <img src={flag} alt="selected flag" />
      {dataLocalStorage.getLanguage() || 'German'}
      <ArrowDown
        className="arrow"
        onClick={() => {
          if (window.innerWidth < 769) {
            setDropdownOpen(!dropdownOpen)
          }
        }}
      />
      <div className="select-languge_dropdown">
        {languages
          .sort(function (a, b) {
            var lang1 = a.lang.toUpperCase()
            var lang2 = b.lang.toUpperCase()
            if (lang1 < lang2) {
              return -1
            }
            if (lang1 > lang2) {
              return 1
            }
            return 0
          })
          .map((thisLang, i) => {
            if (dataLocalStorage.getLanguage() !== thisLang.lang) {
              return (
                <SingleLanguage
                  value={thisLang.value}
                  key={i}
                  changeLanguage={() => {
                    changeLanguage(thisLang.lang, thisLang.value)
                    setDropdownOpen(false)
                  }}
                  styles={thisLang.value === props.language && 'selected'}
                  language={thisLang.lang}
                  flag={thisLang.flag}
                />
              )
            } else {
              return null
            }
          })}
      </div>
    </div>
  )
}

export default SelectLanguage
