import './Dropdown.scss'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const dropdownLinks = [
  {
    text: <FormattedMessage defaultMessage="Our Work" id="OurWork" />,
    link: '/our-work',
  },
  {
    text: <FormattedMessage defaultMessage="Contact Us" id="ContactUs" />,
    link: '/contact-us',
  },
]

function DropdownLink({ link, text, onClick }) {
  return (
    <NavLink
      to={link}
      onClick={onClick}
      className="dropdown-link flex align-center"
    >
      {text}
    </NavLink>
  )
}

export default function Dropdown({ openedDropdown, onClick }) {
  return (
    <div className={`dropdown flex column ${openedDropdown && 'opened'}`}>
      {dropdownLinks.map((i, index) => (
        <DropdownLink
          link={i.link}
          text={i.text}
          onClick={onClick}
          key={index}
        />
      ))}
    </div>
  )
}
